import React from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { Box, Collapse, Fade } from '@material-ui/core'
import _ from 'lodash'

import ButtonLink from '../../../component/buttonLink'
import { Search, sizes } from '../../../component/icons'
import PageHeader, { pageHeaderTopBottomPadding } from '../../../component/PageHeader/pageHeader'
import { newSelectItem, SingleSelect } from '../../../component/Select'
import Title from '../../../component/title/title'
import Layout from '../../layout'
import { createNewFullPaddedButton } from '../../../component/buttons/defaultButton'
import { LoadingButton } from '../../../component/buttons/loadingButton'
import { connect } from 'react-redux'
import { configurationSelector, splitPeriodSelector } from '../../../store/selectors'
import { isEmpty } from '../../../businessRules/string'
import store from '../../../store'
import { setSplitPeriodSearchSelectors, getSplitPeriod } from '../../../store/actions/splitPeriod'

const CancelButton = createNewFullPaddedButton({ t: 0.75, r: 3, b: 0.75, l: 3 }, '100px')
const SearchButton = LoadingButton(createNewFullPaddedButton({ t: 0.75, r: 3, b: 0.75, l: 3 }, '100px'))

const mapStateToProps = state => ({
  regions: configurationSelector(state).regions,
  cabins: configurationSelector(state).cabins,
  odsByRegion: configurationSelector(state).odsByRegion,
  searchSelectors: splitPeriodSelector(state).searchSelectors,
  splitPeriodType: configurationSelector(state).splitPeriod.type,
  isSearchEnabled: splitPeriodSelector(state).isSearchEnabled
})

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSearchPanelOpen: false,
      isSearchClicked: false
    }
  }

  render() {
    const { isSearchPanelOpen, isSearchClicked } = this.state
    const { regions, cabins, odsByRegion, searchSelectors, splitPeriodType, isSearchEnabled } = this.props

    const open = () => this.setState({ isSearchPanelOpen: true })
    const close = () => this.setState({ isSearchPanelOpen: false })

    const setSearchSelectors = (selectors) => store.dispatch(setSplitPeriodSearchSelectors(selectors))

    const originIdSelector = (od, region) => _.chain(odsByRegion).filter(i => i.region === region).first().get('ods').filter(ods => ods.od === od).first().get('originId')

    const destinationIdSelector = (od, region) => _.chain(odsByRegion).filter(i => i.region === region).first().get('ods').filter(ods => ods.od === od).first().get('destinationId')

    const onSearch = () => {
      this.setState({ isSearchClicked: true })
      store.dispatch(getSplitPeriod(searchSelectors))
    }

    return (
      <PageHeader>
        <Layout>
          <Box display='flex'>
            <Box pb={pageHeaderTopBottomPadding}>
              <Title labelId='view.settings.title' mb={2}>
                <Fade in={!isSearchPanelOpen}>
                  <div>
                    <ButtonLink labelId='view.settings.searchButtonLink' onClick={open} endIcon={<Search size={sizes.small} />} />
                  </div>
                </Fade>
              </Title>
              <Collapse in={isSearchPanelOpen}>
                <Box display='flex' flexDirection='row' alignItems='center' justify='flex-start' marginBottom={2}>
                  <Box ml={1}>
                    <SingleSelect
                      elements={splitPeriodType.map(t => newSelectItem(t.id, t.value, t.value))}
                      required
                      error={isSearchClicked && isEmpty(searchSelectors.periodType)}
                      titleId='selectorTitle'
                      labelId='view.settings.selectors.type'
                      updateValue={type => setSearchSelectors({ periodType: type, region: '', od: '', originId: '', destinationId: '', cabin: '' })}
                      value={searchSelectors.periodType}
                    />
                  </Box>
                  {searchSelectors.periodType !== 'System' &&
                    <Box ml={1}>
                      <SingleSelect
                        elements={regions.map(r => newSelectItem(r.id, r.description, r.description))}
                        required
                        error={isSearchClicked && isEmpty(searchSelectors.region)}
                        titleId='selectorTitle'
                        labelId='view.settings.selectors.service'
                        updateValue={region => setSearchSelectors({ region: region, od: '', originId: '', destinationId: '' })}
                        value={searchSelectors.region}
                      />
                    </Box>}
                  {searchSelectors.periodType === 'OD' &&
                    <Box ml={1}>
                      <SingleSelect
                        elements={_.chain(odsByRegion).filter(i => i.region === searchSelectors.region)
                          .first().get('ods').map(r => newSelectItem(r.od, r.od, r.od)).value()}
                        required
                        error={isSearchClicked && isEmpty(searchSelectors.od)}
                        titleId='selectorTitle'
                        labelId='view.settings.selectors.od'
                        updateValue={od => setSearchSelectors({
                          od: od,
                          originId: originIdSelector(od, searchSelectors.region),
                          destinationId: destinationIdSelector(od, searchSelectors.region)
                        })}
                        value={searchSelectors.od}
                      />
                    </Box>}
                  {searchSelectors.periodType === 'OD' &&
                    <Box ml={1}>
                      <SingleSelect
                        elements={cabins.map(c => newSelectItem(c.id, c.value, c.value))}
                        required
                        error={isSearchClicked && isEmpty(searchSelectors.cabin)}
                        titleId='selectorTitle'
                        labelId='view.settings.selectors.cabin'
                        updateValue={cabin => setSearchSelectors({ cabin: cabin })}
                        value={searchSelectors.cabin}
                      />
                    </Box>}
                  <Box ml={1}>
                    <SearchButton variant='contained' color='primary' onClick={onSearch} isLoading={isSearchEnabled}>
                      <Translate id='view.portfolio.search' />
                    </SearchButton>
                  </Box>
                  <Box ml={1}>
                    <CancelButton variant='contained' onClick={close}>
                      <Translate id='view.portfolio.cancel' />
                    </CancelButton>
                  </Box>
                </Box>
              </Collapse>
            </Box>
          </Box>
        </Layout>
      </PageHeader>
    )
  }
}

Header.propTypes = {
  regions: PropTypes.array.isRequired,
  cabins: PropTypes.array.isRequired,
  odsByRegion: PropTypes.array.isRequired,
  searchSelectors: PropTypes.object.isRequired,
  splitPeriodType: PropTypes.array.isRequired,
  isSearchEnabled: PropTypes.bool.isRequired
}

export default connect(mapStateToProps)(Header)
