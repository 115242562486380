import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import { StringInput } from '../../../component/input'
import { splitPeriodSelector } from '../../../store/selectors'
import PropTypes from 'prop-types'
import store from '../../../store'
import { setAddSplitPeriodSelectors, setSplitPeriodUpdateSelectors } from '../../../store/actions/splitPeriod'
import { isEmpty } from '../../../businessRules/string'

const mapStateToProps = state => ({
  updateSelector: splitPeriodSelector(state).updateSelector,
  addSelector: splitPeriodSelector(state).addSelector,
  isUpdateClicked: splitPeriodSelector(state).isUpdateClicked,
  isAddClicked: splitPeriodSelector(state).isAddClicked
})

const useStyles = makeStyles({
  root: {
    fontWeight: 700
  }
})

function CreatePeriodName({ row, updateSelector, addSelector, isUpdateClicked, isAddClicked }) {
  const classes = useStyles()

  function handleChange(value, row) {
    row.add ? store.dispatch(setAddSplitPeriodSelectors(value)) : store.dispatch(setSplitPeriodUpdateSelectors(value))
  }

  return (
    <Box m={1}>
      {!row.edit && <Box mt={1} mb={1}><Typography variant='body1' className={classes.root}>{row.name}</Typography></Box>}
      {row.edit &&
        <StringInput
          labelId='view.settings.selectors.name'
          error={row.add ? isAddClicked && isEmpty(addSelector.name) : isUpdateClicked && isEmpty(addSelector.name)}
          value={row.add ? addSelector.name : updateSelector.name}
          onChange={value => handleChange({ name: value }, row)}
        />}
    </Box>
  )
}

CreatePeriodName.propTypes = {
  row: PropTypes.object.isRequired,
  updateSelector: PropTypes.object.isRequired,
  addSelector: PropTypes.object.isRequired,
  isUpdateClicked: PropTypes.bool.isRequired,
  isAddClicked: PropTypes.bool.isRequired
}

export default connect(mapStateToProps)(CreatePeriodName)
