import { resultHandler } from '..'
import { api } from '../../../appSetting'
import { newError } from '../error'
import { fetch, fetchDelete, fetchPost, fetchPut } from '../fetch'

const splitPeriodBaseUrl = api.splitperiod

export const SplitPeriodUpdating = 'SplitPeriodUpdating'
export const splitPeriodUpdating = (row) => ({ type: SplitPeriodUpdating, ...row })

export const SplitPeriodUndoUpdating = 'SplitPeriodUndoUpdating'
export const splitPeriodUndoUpdating = (row) => ({ type: SplitPeriodUndoUpdating, ...row })

export const SetSplitPeriodSearchSelectors = 'SetSplitPeriodSearchSelectors'
export const setSplitPeriodSearchSelectors = (selectors) => ({ type: SetSplitPeriodSearchSelectors, selectors })

export const SetSplitPeriodCloneSelectors = 'SetSplitPeriodCloneSelectors'
export const setSplitPeriodCloneSelectors = (selectors) => ({ type: SetSplitPeriodCloneSelectors, selectors })

export const SetSplitPeriodUpdateSelectors = 'SetSplitPeriodUpdateSelectors'
export const setSplitPeriodUpdateSelectors = (selectors) => ({ type: SetSplitPeriodUpdateSelectors, selectors })

export const SetAddSplitPeriodSelectors = 'SetAddSplitPeriodSelectors'
export const setAddSplitPeriodSelectors = (selectors) => ({ type: SetAddSplitPeriodSelectors, selectors })

export const getSplitPeriod = (selectors) => async dispatch => {
  dispatch(requestedSplitPeriod())

  const fetcher = fetch(`${splitPeriodBaseUrl}/`, { ...selectors })
  const onError = newError('view.settings.error.getting', fetcher.correlationId)

  return dispatch(fetcher)
    .then(resultHandler(receivedSplitPeriod, onError))
    .then(dispatch)
    .catch(error => dispatch(onError(error)))
}

export const RequestedSplitPeriod = 'RequestedSplitPeriod'
export const requestedSplitPeriod = () => ({ type: RequestedSplitPeriod })

export const ReceivedSplitPeriod = 'ReceivedSplitPeriod'
export const receivedSplitPeriod = splitPeriods => ({ type: ReceivedSplitPeriod, splitPeriods })

export const AddSplitPeriodClicked = 'AddSplitPeriodClicked'
export const addSplitPeriodClicked = isClicked => ({ type: AddSplitPeriodClicked, isClicked })

export const addSplitPeriod = (selectors) => async dispatch => {
  dispatch(addingSplitPeriod)

  const fetcher = fetchPost(`${splitPeriodBaseUrl}/add`, { ...selectors })
  const onError = newError('view.settings.error.adding', fetcher.correlationId)

  return dispatch(fetcher)
    .then(resultHandler(splitPeriodAdded, onError))
    .then(sp => dispatch(sp))
    .then(() => dispatch(getSplitPeriod(selectors)))
    .catch(error => dispatch(onError(error)))
}

export const AddingSplitPeriod = 'AddingSplitPeriod'
export const addingSplitPeriod = () => ({ type: AddingSplitPeriod })

export const SplitPeriodAdded = 'SplitPeriodAdded'
export const splitPeriodAdded = (data) => ({ type: SplitPeriodAdded })

export const removeSplitPeriod = (splitPeriod) => dispatch => {
  dispatch(removingSplitPeriod)

  const fetcher = fetchDelete(`${splitPeriodBaseUrl}`, splitPeriod.id)
  const onError = newError('view.settings.error.deleting', fetcher.correlationId)

  return dispatch(fetcher)
    .then(resultHandler(splitPeriodRemoved, onError))
    .then(dispatch)
    .then(() => dispatch(getSplitPeriod(splitPeriod)))
    .catch(error => dispatch(onError(error)))
}

export const RemovingSplitPeriod = 'RemovingSplitPeriod'
export const removingSplitPeriod = () => ({ type: RemovingSplitPeriod })

export const SplitPeriodRemoved = 'SplitPeriodRemoved'
export const splitPeriodRemoved = (data) => ({ type: SplitPeriodRemoved })

export const updateSplitPeriod = (selectors) => dispatch => {
  const fetcher = fetchPut(`${splitPeriodBaseUrl}`, selectors)
  const onError = newError('view.settings.error.updating', fetcher.correlationId)

  return dispatch(fetcher)
    .then(resultHandler(splitPeriodUpdated, onError))
    .then(dispatch)
    .then(() => dispatch(getSplitPeriod(selectors)))
    .catch(error => dispatch(onError(error)))
}

export const SplitPeriodUpdated = 'SplitPeriodUpdated'
export const splitPeriodUpdated = (data) => ({ type: SplitPeriodUpdated, data })
