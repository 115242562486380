import React from 'react'
import PropTypes from 'prop-types'
import { Box, Checkbox, FormControlLabel, FormGroup, withStyles } from '@material-ui/core'
import { SearchInput } from '../../../component/input'
import { connect } from 'react-redux'
import { configurationSelector, splitPeriodSelector, userSettingsSelector } from '../../../store/selectors'
import { createNewFullPaddedButton } from '../../../component/buttons/defaultButton'
import { Translate } from 'react-localize-redux'
import ActionIconButton from '../../../component/ActionIconButton'
import CloseIcon from '@material-ui/icons/Close'
import { newSelectItem, SingleSelect } from '../../../component/Select'
import store from '../../../store'
import { setSplitPeriodCloneSelectors } from '../../../store/actions/splitPeriod'
import { isEmpty } from '../../../businessRules/string'
import _ from 'lodash'
import { isArrayEmpty } from '../../../businessRules/array'
import { Subtitle1, SubTitle4 } from '../../../component/title/subtitle'
import ButtonLink from '../../../component/buttonLink'
import { LoadingButton } from '../../../component/buttons/loadingButton'

const OverrideButton = LoadingButton(createNewFullPaddedButton({ t: 0.75, r: 0.75, b: 0.75, l: 0.75 }, '150px'))

const mapStateToProps = state => ({
  regions: configurationSelector(state).regions,
  cabins: configurationSelector(state).cabins,
  odDmMapping: configurationSelector(state).odDmMapping,
  odsByRegion: configurationSelector(state).odsByRegion,
  cloneSelectors: splitPeriodSelector(state).cloneSelectors,
  splitPeriodType: configurationSelector(state).splitPeriod.type,
  isAdmin: userSettingsSelector(state).isAdmin,
  splitPeriods: splitPeriodSelector(state).splitPeriods
})

const style = theme => ({
  boxBg: {
    backgroundColor: theme.palette.bgColor['color-neutral-95']
  },
  searchPanel: {
    backgroundColor: 'white',
    border: 'lightgrey 1px solid',
    width: '100%'
  },
  flex_sb: {
    display: 'flex',
    justifyContent: 'space-between'
  }
})

function CloneSplitPeriod({
  classes, regions, cabins, odDmMapping,
  odsByRegion, close, cloneSelectors, splitPeriodType, isAdmin, isCalibrate, splitPeriods
}) {
  const [override, setOverride] = React.useState(false)
  const [selectAll, setSelectAll] = React.useState(false)
  const [odsForSelection, setOdsForSelection] = React.useState({})
  const [searchInput, setSearchInput] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState('')

  const setCloneSelector = (selectors) => store.dispatch(setSplitPeriodCloneSelectors(selectors))
  const getOdsForSelection = () => _.chain(odsByRegion).filter(ods => ods.region === cloneSelectors.region).first().value() ?? {}

  const selectOD = (event, ods) => {
    event.target.checked
      ? setCloneSelector({ od: cloneSelectors.od.concat(ods) })
      : setCloneSelector({ od: cloneSelectors.od.filter(od => od.od !== ods.od) })
    setSelectAll(false)
  }

  const selectAllOD = (event) => {
    event.target.checked
      ? setCloneSelector({ od: _.uniqBy(cloneSelectors.od.concat(odsForSelection.ods), 'od') })
      : setCloneSelector({ od: [] })
    setSelectAll(event.target.checked)
  }

  const isSelected = (ods) => {
    return _.isEmpty(cloneSelectors.od) ? false : !_.chain(cloneSelectors.od).filter(od => od.od === ods.od).isEmpty().value()
  }

  const clearAllCloneSelectors = () => {
    setCloneSelector({ od: [] })
    setSelectAll(false)
    setSearchInput('')
    setOdsForSelection(getOdsForSelection)
  }

  const filterOdSelectors = value => {
    setSearchInput(value)
    if (value.length >= 3 || value === '') {
      setSelectAll(false)

      value === ''
        ? setOdsForSelection(getOdsForSelection)
        : setOdsForSelection({ ...odsForSelection, ods: getOdsForSelection().ods.filter(od => od.od.includes(value.toUpperCase())) })
    }
  }

  const isValidOverride = !(
    isEmpty(cloneSelectors.periodType) ||
    isEmpty(cloneSelectors.region) ||
    isEmpty(cloneSelectors.cabin) ||
    isArrayEmpty(cloneSelectors.od)
  )

  const overrideSplitPeriod = () => {
    setOverride(true)
    if (isValidOverride && splitPeriods.length > 1) {
      console.log(cloneSelectors)
    } else {
      setErrorMessage('Please do add split period to below table')
    }
  }

  React.useEffect(() => setOdsForSelection(getOdsForSelection), [cloneSelectors.region])

  return (
    <Box className={classes.boxBg} p={3}>
      <Box className={classes.flex_sb} mb={1}>
        <Box display='flex'>
          {isAdmin &&
            <Box>
              <SingleSelect
                elements={splitPeriodType.map(t => newSelectItem(t.id, t.value, t.value))}
                required
                error={override && isEmpty(cloneSelectors.periodType)}
                titleId='selectorTitle'
                labelId='view.settings.selectors.type'
                updateValue={type => setCloneSelector({ periodType: type })}
                value={cloneSelectors.periodType}
              />
            </Box>}
          {cloneSelectors.periodType !== 'System' &&
            <Box ml={1}>
              <SingleSelect
                elements={regions.map(r => newSelectItem(r.id, r.description, r.description))}
                required
                error={override && isEmpty(cloneSelectors.region)}
                titleId='selectorTitle'
                labelId='view.settings.selectors.service'
                updateValue={region => setCloneSelector({ region: region })}
                value={cloneSelectors.region}
              />
            </Box>}
          {cloneSelectors.periodType === 'OD' &&
            <Box ml={1}>
              <SingleSelect
                elements={cabins.map(c => newSelectItem(c.id, c.value, c.value))}
                required
                error={override && isEmpty(cloneSelectors.cabin)}
                titleId='selectorTitle'
                labelId='view.settings.selectors.cabin'
                updateValue={cabin => setCloneSelector({ cabin: cabin })}
                value={cloneSelectors.cabin}
              />
            </Box>}
        </Box>
        <Box>
          <ActionIconButton
            title='close'
            size='small'
            onClick={close}
          >
            <CloseIcon fontSize='large' />
          </ActionIconButton>
        </Box>
      </Box>
      {!isEmpty(cloneSelectors.region) && cloneSelectors.periodType === 'OD' &&
        <>
          <Box display='flex' alignItems='center'>
            <Box mr={2}>
              <SearchInput
                labelId='view.settings.selectors.od'
                fullWidth={false}
                value={searchInput}
                onChange={filterOdSelectors}
              />
            </Box>
            <Box mr={2}>
              <Subtitle1>{cloneSelectors.od.length}<Translate id='view.settings.selectedOdCount' /></Subtitle1>
            </Box>
            <Box mr={2}>
              <ButtonLink labelId='view.settings.clearAll' onClick={clearAllCloneSelectors} />
            </Box>
            <Box>
              <SubTitle4 style={{ color: 'red' }}>{errorMessage}</SubTitle4>
            </Box>
          </Box>
          <Box className={classes.flex_sb}>
            <Box className={classes.searchPanel} p={1}>
              <Box>
                <FormControlLabel
                  key={0}
                  style={{ margin: '0', padding: '0' }}
                  control={
                    <Checkbox
                      key={0}
                      color='primary'
                      padding='checkbox'
                      onClick={event => selectAllOD(event)}
                      checked={selectAll}
                    />
                  }
                  label='Sellect All'
                />
              </Box>
              <Box>
                <FormGroup row>
                  {!_.isEmpty(odsForSelection) && odsForSelection.ods.map(ods => {
                     return (
                      <FormControlLabel
                        key={ods.od}
                        style={{ margin: '0', padding: '0', width: '8rem', height: '1.7rem' }}
                        control={
                          <Checkbox
                            key={ods.od}
                            color='primary'
                            padding='checkbox'
                            style={{ marginTop: '0', marginBottom: '0' }}
                            onClick={event => selectOD(event, ods)}
                            checked={isSelected(ods)}
                          />
                        }
                        label={ods.od}
                      />)
                  })}
                </FormGroup>
              </Box>
            </Box>
          </Box>
        </>}
      <Box display='flex' mt={1} justifyContent='flex-end'>
        <Box ml={1}>
          <OverrideButton variant='contained' color='primary' onClick={overrideSplitPeriod}>
            <Translate id='view.settings.copy' />
          </OverrideButton>
        </Box>
      </Box>
    </Box>
  )
}

CloneSplitPeriod.propTypes = {
  classes: PropTypes.object,
  regions: PropTypes.array.isRequired,
  cabins: PropTypes.array.isRequired,
  odDmMapping: PropTypes.array.isRequired,
  odsByRegion: PropTypes.array.isRequired,
  close: PropTypes.func.isRequired,
  cloneSelectors: PropTypes.object.isRequired,
  splitPeriodType: PropTypes.array.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isCalibrate: PropTypes.bool.isRequired,
  splitPeriods: PropTypes.array.isRequired
}

export default connect(mapStateToProps)(withStyles(style)(CloneSplitPeriod))
