import { Box, Collapse, Grow } from '@material-ui/core'
import React from 'react'
import { Translate } from 'react-localize-redux'
import { createNewFullPaddedButton } from '../../component/buttons/defaultButton'
import TableTitle from '../../component/table/tableTitle'
import Layout from '../layout'
import DatePeriodTable from './component/datePeriodTable'
import Header from './component/header'
import CloneSplitPeriod from './component/cloneSplitPeriod'

const CloneButton = createNewFullPaddedButton({ t: 0.75, r: 3, b: 0.75, l: 3 }, '75px')

class Settings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isCopyDOpen: false
    }
  }

  render() {
    const { isCopyDOpen } = this.state

    const open = () => this.setState({ isCopyDOpen: true })
    const close = () => this.setState({ isCopyDOpen: false })

    return (
      <>
        <Header />
        <Layout>
          <Box mt={2} display='flex' justifyContent='space-between'>
            <TableTitle labelId='view.settings.subtitle' mb={2} />
            <Box display='flex'>
              <Grow in={!isCopyDOpen}>
                <Box m={1}>
                  <CloneButton variant='contained' color='secondary' onClick={open}>
                    <Translate id='view.settings.cloneButton' />
                  </CloneButton>
                </Box>
              </Grow>
            </Box>
          </Box>
          <Collapse in={isCopyDOpen}>
            <CloneSplitPeriod close={close} />
          </Collapse>
          <DatePeriodTable />
        </Layout>
      </>
    )
  }
}

export default Settings
