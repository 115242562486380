import { combineReducers } from 'redux'

import filter from './filters/filters'
import demandeForecast from './demandeForecast'
import influences from './influences'
import optimizations from './optimization'
import userSettings from './userSettings'
import tableState from './tableState'
import views from './views'
import heatmap from './heatmap'
import postdate from './PostDate'
import error from './error'
import commercialInfluence from './commercialInfluence'
import rows from './rows'
import configurations from './configuration'
import newCommercialInfluence from './newCommercialInfluence'
import users from './users'
import newUser from './newUser'
import portfolio from './portfolio'
import splitPeriod from './splitPeriod'

export default combineReducers({
  filter,
  demandeForecast,
  influences,
  optimizations,
  userSettings,
  tableState,
  views,
  heatmap,
  postdate,
  error,
  commercialInfluence,
  rows,
  configurations,
  newCommercialInfluence,
  users,
  newUser,
  portfolio,
  splitPeriod
})
