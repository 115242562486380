import React from 'react'
import PropTypes from 'prop-types'

import CustomFormControl from '../customFormControl/customFormControl'
import CustomInputLabel from './inputLabel'
import DefaultButton from '../buttons/defaultButton'
import { CalendarToday } from '@material-ui/icons'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { SingleDatePicker } from 'react-dates'

import { Translate, withLocalize } from 'react-localize-redux'
import { SplitPeriodDateFormat } from '../../appSetting'

function DateInput({ labelId, selectedDate, id, onChange, error }) {
  const [date, setDate] = React.useState(selectedDate)
  const [focusedInput, setfocusedInput] = React.useState(false)

  return (
    <CustomFormControl error={error} fullWidth>
      <CustomInputLabel shrink><Translate id={labelId} /></CustomInputLabel>
      <SingleDatePicker
        appendToBody
        noBorder
        openDirection='up'
        id={`${id}`}
        date={date}
        onDateChange={(date) => {
          setDate(date)
          onChange(date)
        }}
        focused={focusedInput}
        onFocusChange={({ focused }) => setfocusedInput(focused)}
        displayFormat={SplitPeriodDateFormat}
        numberOfMonths={1}
        isOutsideRange={day => false}
      />
      <DefaultButton size='small' variant='contained' onClick={() => setfocusedInput(true)}>
        <CalendarToday />
      </DefaultButton>
    </CustomFormControl>
  )
}

DateInput.propTypes = {
  labelId: PropTypes.string.isRequired,
  selectedDate: PropTypes.object.isRequired,
  id: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool
}

export default withLocalize(DateInput)
