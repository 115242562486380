import React from 'react'
import { Box, Tooltip } from '@material-ui/core'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ActionIconButton from '../../../component/ActionIconButton'
import { CheckCircleOutline, EditOutlined } from '@material-ui/icons'
import { Add, Minus, Undo } from '../../../component/icons'
import store from '../../../store'
import {
  addSplitPeriod,
  addSplitPeriodClicked,
  splitPeriodUndoUpdating,
  splitPeriodUpdating,
  removeSplitPeriod,
  updateSplitPeriod
} from '../../../store/actions/splitPeriod'
import { isEmpty } from '../../../businessRules/string'
import { splitPeriodSelector } from '../../../store/selectors'

const mapStateToProps = state => ({
  searchSelectors: splitPeriodSelector(state).searchSelectors,
  updateSelector: splitPeriodSelector(state).updateSelector,
  addSelector: splitPeriodSelector(state).addSelector
})

function DatePeriodTableActions({ row, searchSelectors, updateSelector, addSelector }) {
  const edit = (row) => store.dispatch(splitPeriodUpdating(row))
  const undo = (row) => store.dispatch(splitPeriodUndoUpdating(row))
  const remove = (row) => store.dispatch(removeSplitPeriod(row))

  const isAddSplitPeriodValid = !(
    isEmpty(addSelector.name) ||
    isEmpty(addSelector.startBy) ||
    isEmpty(addSelector.day) ||
    isEmpty(addSelector.exp) ||
    isEmpty(addSelector.date)
  )
  const add = () => {
    store.dispatch(addSplitPeriodClicked(true))
    if (isAddSplitPeriodValid) {
      store.dispatch(addSplitPeriod({ ...addSelector, ...searchSelectors }))
    }
  }

  const isUpdateSplitPeriodValud = !(
    isEmpty(updateSelector.name) ||
    isEmpty(updateSelector.startBy) ||
    isEmpty(updateSelector.day) ||
    isEmpty(updateSelector.exp) ||
    isEmpty(updateSelector.date)
  )
  const doneEdit = () => {
    if (isUpdateSplitPeriodValud) { store.dispatch(updateSplitPeriod(updateSelector)) }
  }

  return (
    <Box key={row.id}>
      {!row.add && !row.edit &&
        <Tooltip title='Edit'>
          <ActionIconButton
            size='small'
            onClick={() => edit(row)}
          >
            <EditOutlined />
          </ActionIconButton>
        </Tooltip>}
      {row.edit && !row.add &&
        <Tooltip title='Undo'>
          <ActionIconButton
            size='small'
            onClick={() => undo(row)}
          >
            <Undo />
          </ActionIconButton>
        </Tooltip>}
      {row.edit && !row.add &&
        <Tooltip title='Done'>
          <ActionIconButton
            size='small'
            onClick={doneEdit}
          >
            <CheckCircleOutline />
          </ActionIconButton>
        </Tooltip>}
      {row.remove && !row.add &&
        <Tooltip title='Remove'>
          <ActionIconButton
            size='small'
            onClick={() => remove(row)}
          >
            <Minus />
          </ActionIconButton>
        </Tooltip>}
      {row.add &&
        <Tooltip title='Add'>
          <ActionIconButton
            size='small'
            onClick={add}
          >
            <Add />
          </ActionIconButton>
        </Tooltip>}
    </Box>
  )
}

DatePeriodTableActions.propTypes = {
  row: PropTypes.object.isRequired,
  searchSelectors: PropTypes.object.isRequired,
  updateSelector: PropTypes.object.isRequired,
  addSelector: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(DatePeriodTableActions)
