import {
  SplitPeriodUndoUpdating,
  SplitPeriodUpdating,
  ReceivedSplitPeriod,
  SetSplitPeriodSearchSelectors,
  SetSplitPeriodCloneSelectors,
  SetSplitPeriodUpdateSelectors,
  SetAddSplitPeriodSelectors,
  AddSplitPeriodClicked,
  SplitPeriodAdded,
  RequestedSplitPeriod
} from '../../actions/splitPeriod'
import _ from 'lodash'

const initialState = {
  isSearchEnabled: false,
  searchSelectors: {
    periodType: 'OD',
    region: '',
    od: '',
    originId: '',
    destinationId: '',
    cabin: ''
  },
  splitPeriods: [{ id: 99999, name: '', startBy: '', day: '', exp: '', date: '', edit: true, remove: false, add: true }],
  newSplitPeriod: { id: 99999, name: '', startBy: '', day: '', exp: '', date: '', edit: true, remove: false, add: true },
  cloneSelectors: {
    periodType: 'OD',
    region: '',
    od: [],
    cabin: ''
  },
  updateSelector: {},
  addSelector: { name: '', startBy: '', day: '', exp: '', date: '' },
  isUpdateClicked: false,
  isAddClicked: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SplitPeriodUpdating: {
      const updateSplitPeriod = (sp) => sp.id === action.id
        ? { ...sp, edit: true, remove: false }
        : { ...sp, edit: !!sp.add, remove: state.splitPeriods.length > 4 }

      const setUpdateSelectors = _.chain(state.splitPeriods).filter(sp => sp.id === action.id).first().value()

      return { ...state, splitPeriods: state.splitPeriods.map(updateSplitPeriod), updateSelector: setUpdateSelectors }
    }
    case SplitPeriodUndoUpdating: {
      const updateSplitPeriod = (sp) => sp.id === action.id ? { ...sp, edit: false, remove: state.splitPeriods.length > 4 } : sp
      return { ...state, splitPeriods: state.splitPeriods.map(updateSplitPeriod), updateSelector: { ...initialState.updateSelector } }
    }
    case SetSplitPeriodSearchSelectors: {
      return {
        ...state,
        searchSelectors: { ...state.searchSelectors, ...action.selectors },
        splitPeriods: [...initialState.splitPeriods],
        updateSelector: { ...initialState.updateSelector }
      }
    }
    case SetSplitPeriodCloneSelectors: {
      return { ...state, cloneSelectors: { ...state.cloneSelectors, ...action.selectors } }
    }
    case SetSplitPeriodUpdateSelectors: {
      return { ...state, updateSelector: { ...state.updateSelector, ...action.selectors } }
    }
    case SetAddSplitPeriodSelectors: {
      return { ...state, addSelector: { ...state.addSelector, ...action.selectors } }
    }
    case RequestedSplitPeriod: {
      return { ...state, isSearchEnabled: true }
    }
    case ReceivedSplitPeriod: {
      const addEditRemove = sp => ({ ...sp, edit: false, remove: true, add: false })
      const addEdit = sp => ({ ...sp, edit: false, remove: false, add: false })
      const addActionToSplitPeriods = splitPeriods => splitPeriods.length > 3 ? splitPeriods.map(addEditRemove) : splitPeriods.map(addEdit)
      const requestedSplitPeriods = splitPeriods => splitPeriods.length < 6 ? splitPeriods.concat(initialState.newSplitPeriod) : splitPeriods

      return { ...state, splitPeriods: requestedSplitPeriods(addActionToSplitPeriods(action.splitPeriods)), isSearchEnabled: false }
    }
    case AddSplitPeriodClicked: {
      return { ...state, isAddClicked: action.isClicked }
    }
    case SplitPeriodAdded: {
      return { ...state, isAddClicked: false, addSelector: initialState.addSelector }
    }
    default:
      return state
  }
}
