import React from 'react'
import PropTypes from 'prop-types'

import SearchIcon from '@material-ui/icons/Search'
import { createColorFullHeightDefaultButton } from '../buttons/defaultButton'

import { withLocalize } from 'react-localize-redux'

const ColoredButton = createColorFullHeightDefaultButton(theme => ({ color: theme.icon.baseOnWhite, hoverColor: theme.icon.hover }))

const SearchButton = ({ titleId, onClick, translate }) =>
  <ColoredButton title={translate(titleId ?? 'buttonClear')} color='inherit' variant='contained' size='small' onClick={onClick}>
    <SearchIcon />
  </ColoredButton>

SearchButton.propTypes = {
  titleId: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
}

export default withLocalize(SearchButton)
