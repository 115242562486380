import React from 'react'
import PropTypes from 'prop-types'

import CustomFormControl from '../customFormControl/customFormControl'
import { CustomInputLabel, FilledInput } from './index'

import { Translate } from 'react-localize-redux'
import SearchButton from './searchButton'

const SearchInput = ({ labelId, value, onChange, fullWidth }) =>
  <CustomFormControl fullWidth={fullWidth}>
    <CustomInputLabel><Translate id={labelId} /></CustomInputLabel>
    <FilledInput
      fullWidth={fullWidth}
      value={value}
      type='text'
      onChange={({ target: { value } }) => onChange(value)}
      endAdornment={<SearchButton onClick={() => onChange(value)} />}
    />
  </CustomFormControl>

SearchInput.propTypes = {
  labelId: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool.isRequired
}

export default SearchInput
