import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { splitPeriodSelector } from '../../../store/selectors'
import CreatePeriodName from './createPeriodName'
import DatePeriodTableAction from './tableActions'
import CreateDateExp from './createDateExp'

const mapStatetoprops = state => ({
  splitPeriods: splitPeriodSelector(state).splitPeriods
})
class DatePeriodTable extends React.Component {
  render() {
    const { splitPeriods } = this.props

    return (
      <Box mt={2} mb={5}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '20%' }}>Period Name</TableCell>
                <TableCell>Start Date Expression</TableCell>
                <TableCell style={{ width: '10%' }} align='right'>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {splitPeriods.map(row => {
                return (
                  <TableRow hover key={row.id}>
                    <TableCell padding='checkbox'><CreatePeriodName row={row} /></TableCell>
                    <TableCell padding='checkbox'><CreateDateExp row={row} /></TableCell>
                    <TableCell padding='checkbox' align='right'>
                      <DatePeriodTableAction row={row} />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  }
}

DatePeriodTable.propTypes = {
  splitPeriods: PropTypes.array.isRequired
}

export default connect(mapStatetoprops)(DatePeriodTable)
