import React from 'react'
import PropTypes from 'prop-types'

import CustomFormControl from '../customFormControl/customFormControl'
import { CustomInputLabel, FilledInput } from './index'
import ClearButton from './clearButton'

import { Translate } from 'react-localize-redux'

const StringInput = ({ labelId, value, onChange, defaultValue, error }) =>
  <CustomFormControl error={error} fullWidth>
    <CustomInputLabel><Translate id={labelId} /></CustomInputLabel>
    <FilledInput
      fullWidth
      value={value}
      type='text'
      onChange={({ target: { value } }) => onChange(value)}
      endAdornment={<ClearButton onClick={() => onChange(defaultValue ?? '')} />}
    />
  </CustomFormControl>

StringInput.propTypes = {
  labelId: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  error: PropTypes.bool
}

export default StringInput
