import React from 'react'

import EnhancedTableFooter from '../component/table/tableFooter'

import { connect } from 'react-redux'
import store from '../store'
import { changeRowsPerPage, changePage } from '../store/actions/tableState/tableState'

export default function createTableFooter(tableStateSelector, view) {
  const mapStateToProps = state => {
    const { rowsPerPage, page } = tableStateSelector(state)
    return ({ page, rowsPerPage })
  }

  const setPageTo = page => store.dispatch(changePage(view)(page))
  const onRowsPerPageChange = parseValue => {
    store.dispatch(changeRowsPerPage(view)(parseValue > 0 ? parseValue : 1))
    setPageTo(0)
  }

  const EnhancedTableFooterWrapper = props => <EnhancedTableFooter {...props} onPageChange={setPageTo} onRowsPerPageChange={onRowsPerPageChange} />

  return connect(mapStateToProps)(EnhancedTableFooterWrapper)
}
