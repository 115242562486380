import numberInput from './numberInput'
import stringInput from './stringInput'
import dateRange from './dataRange'
import { CustomFilledInput, CustomFixedFilledInput } from './filledInput'
import inputLabel from './inputLabel'
import dateInput from './dateInput'
import searchInput from './searchInput'

export const DateRange = dateRange
export const NumberInput = numberInput
export const StringInput = stringInput
export const FilledInput = CustomFilledInput
export const FilledInputFixed = CustomFixedFilledInput
export const CustomInputLabel = inputLabel
export const DateInput = dateInput
export const SearchInput = searchInput
