export const DateFormat = 'YYYY-MM-DD'
export const DateDowFormat = 'YYYY-MM-DD / ddd'
export const NotAvailable = 'N/A'
export const SplitPeriodDateFormat = 'MMM DD'

export const routes = {
  welcome: '/',
  loginRedirect: '/loginRedirect',
  logout: '/logout',
  forecasting: {
    path: '/Forecasting',
    params: '/:origin/:destination?/:pos?/:cabin?/:pathGroup?/:dateRangeIndex?',
    toString: function() {
      return `${this.path}${this.params}`
    }
  },
  commercialInfluence: '/CommercialInfluence',
  users: '/users',
  portfolio: '/portfolio',
  settings: '/settings'
}

export const defaultViewSelector = 4
export const viewSelectorEnum = { None: 0, PendingInfluence: 1, Graph: 2, List: 3, Priority: 4 }

export const defaultReviewView = 1
export const ReviewViewEnum = { None: 0, Heatmap: 1, Graph: 2, List: 3 }

export const DefaultInfluence = 1
export const DefaultDecay = 'None'

export const baseRoute = process.env.PUBLIC_URL
export const api = {
  forecasting: `${baseRoute}/api/DemandeForecast`,
  influence: `${baseRoute}/api/Influence`,
  pendinginfluences: `${baseRoute}/api/Influences`,
  optimizations: `${baseRoute}/api/Optimizations`,
  optimization: `${baseRoute}/api/Optimization`,
  userSetting: `${baseRoute}/api/User`,
  commercialInfluence: `${baseRoute}/api/CommercialInfluence`,
  commercialInfluenceUser: `${baseRoute}/api/CommercialInfluence/users`,
  users: `${baseRoute}/api/users`,
  newUsers: `${baseRoute}/api/users/new`,
  user: `${baseRoute}/api/user1`,
  configurations: `${baseRoute}/api/configurations`,
  postdate: `${baseRoute}/api/PostDate`,
  portfolio: `${baseRoute}/api/portfolio`,
  splitperiod: `${baseRoute}/api/splitperiod`
}

export const NumberOfDayForRedAlert = 3
export const DefaultHeatmapThresholdPositive = 100
export const DefaultHeatmapThresholdNegative = -100
