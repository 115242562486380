import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import { DateInput } from '../../../component/input'
import { newSelectItem, SingleSelect } from '../../../component/Select'
import { configurationSelector, splitPeriodSelector } from '../../../store/selectors'
import PropTypes from 'prop-types'
import store from '../../../store'
import { setAddSplitPeriodSelectors, setSplitPeriodUpdateSelectors } from '../../../store/actions/splitPeriod'
import { formatSplitPeriodSelectedDate, splitPeriodDateToMoment } from '../../../businessRules/date'
import { isEmpty } from '../../../businessRules/string'
import moment from 'moment'

const mapStateToProps = state => ({
  splitPeriodConfig: configurationSelector(state).splitPeriod,
  updateSelector: splitPeriodSelector(state).updateSelector,
  addSelector: splitPeriodSelector(state).addSelector,
  isUpdateClicked: splitPeriodSelector(state).isUpdateClicked,
  isAddClicked: splitPeriodSelector(state).isAddClicked
})

const useStyle = makeStyles({
  root: {
    fontWeight: 700
  }
})

function CreateDateExp({ row, splitPeriodConfig, updateSelector, addSelector, isUpdateClicked, isAddClicked }) {
  const classes = useStyle()

  function setSelectors(value, row) {
    row.add ? store.dispatch(setAddSplitPeriodSelectors(value)) : store.dispatch(setSplitPeriodUpdateSelectors(value))
  }

  return (
    <Box width='100%'>
      {!row.edit && <Box mt={2} mb={2}><Typography variant='body1' className={classes.root}>{`${row.startBy} ${row.day} ${row.exp} ${row.date}`}</Typography></Box>}
      {row.edit &&
        <Box p={0} m={0} display='flex' flexWrap='wrap'>
          <Box m={1}>
            <SingleSelect
              elements={splitPeriodConfig.startBy.map(s => newSelectItem(s.id, s.value, s.value))}
              required
              error={row.add ? isAddClicked && isEmpty(addSelector.startBy) : isUpdateClicked && isEmpty(addSelector.startBy)}
              titleId='selectorTitle'
              labelId='view.settings.selectors.start'
              updateValue={value => setSelectors({ startBy: value }, row)}
              value={row.add ? addSelector.startBy : updateSelector.startBy}
            />
          </Box>
          <Box m={1}>
            <SingleSelect
              elements={splitPeriodConfig.day.map(s => newSelectItem(s.id, s.value, s.value))}
              required
              error={row.add ? isAddClicked && isEmpty(addSelector.day) : isUpdateClicked && isEmpty(addSelector.day)}
              titleId='selectorTitle'
              labelId='view.settings.selectors.day'
              updateValue={value => setSelectors({ day: value }, row)}
              value={row.add ? addSelector.day : updateSelector.day}
            />
          </Box>
          <Box m={1}>
            <SingleSelect
              elements={splitPeriodConfig.exp.map(s => newSelectItem(s.id, s.value, s.value))}
              required
              error={row.add ? isAddClicked && isEmpty(addSelector.exp) : isUpdateClicked && isEmpty(addSelector.exp)}
              titleId='selectorTitle'
              labelId='view.settings.selectors.exp'
              updateValue={value => setSelectors({ exp: value }, row)}
              value={row.add ? addSelector.exp : updateSelector.exp}
            />
          </Box>
          <Box m={1}>
            <DateInput
              required
              id={row.id}
              error={row.add ? isAddClicked && isEmpty(addSelector.date) : isUpdateClicked && isEmpty(addSelector.date)}
              labelId='view.settings.selectors.date'
              selectedDate={row.add ? moment() : splitPeriodDateToMoment(updateSelector.date)}
              onChange={date => setSelectors({ date: formatSplitPeriodSelectedDate(date) }, row)}
            />
          </Box>
        </Box>}
    </Box>
  )
}

CreateDateExp.propTypes = {
  row: PropTypes.object.isRequired,
  splitPeriodConfig: PropTypes.object.isRequired,
  updateSelector: PropTypes.object.isRequired,
  addSelector: PropTypes.object.isRequired,
  isUpdateClicked: PropTypes.bool.isRequired,
  isAddClicked: PropTypes.bool.isRequired
}

export default connect(mapStateToProps)(CreateDateExp)
